<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-at="createAt"
      :create-by="createBy"
    >
      <template slot="header" />
      <template slot="content">
        <div class="row">
          <div class="col-7">
            <review-examen style="padding-left: 20px; border-right: 1px solid rgba(0,0,0,0.17);" />
          </div>
          <div class="col-5 analyse-diff">
            <h6> Analyse differentielle</h6>
            <a
              data-toggle="modal"
              data-target="#add-analyse-diff"
              class="btn btn-outline-third btn-sm"
              href="javascript::void(0)"
              v-if="!hasAnalyseDiff && canAddAnalyseDifferencielle"
              title="Ajouter un examen clinique"
            >
              <i class="icofont icofont-plus" /> 
            </a>
            <a
              data-toggle="modal"
              data-target="#update-analyse-diff"
              class="btn btn-outline-info btn-sm"
              href="javascript::void(0)"
              @click.prevent="analyseDiff = selectedExamen.analyseDif"
              v-if="hasAnalyseDiff && canAddAnalyseDifferencielle"
              title="Modifier l'examen clinique"
            >
              <i class="icofont icofont-pencil" /> 
            </a>
            <hr>
            <p>
              {{ selectedExamen.analyseDif }}
            </p>
          </div>
        </div>
      </template>
    </detail-sanitaire>
    
    <modal
      id="add-analyse-diff"
      :footer="false"
      title="Ajouter l'analyse differentielle"
      size="large"
    >
      <form @submit.prevent="addAnalyseDiff">
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="analyse-diff"> Analyse differentielle</label>
          <textarea
            class="form-control"
            name="analyse-diff"
            id="analyse-diff"
            cols="30"
            rows="10"
            v-model="analyseDiff"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      id="update-analyse-diff"
      :footer="false"
      title="Modification de l'analyse differentielle"
      size="large"
    >
      <form @submit.prevent="addAnalyseDiff">
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="analyse-diff"> Analyse differentielle</label>
          <textarea
            class="form-control"
            name="analyse-diff"
            id="analyse-diff"
            cols="30"
            rows="10"
            v-model="analyseDiff"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Modal from '../../../components/modal.vue';
import navbar from '../../../components/navbar.vue';
import {UPDATE_EXAMEN_CLINIQUE} from '../../../graphql/sanitaire'
import ReviewExamen from '../../../components/sanitaire/questionnaire/review.vue'
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue';
export default {
    components: {navbar, DetailSanitaire, ReviewExamen, Modal}, 
    data(){
        return {
          navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Examen clinique',
                    route: 'examen_clinique'
                },
                {
                  libelle: 'Détail'
                }
                ],
                pageIcon: 'la-heartbeat',
                pageTitle: 'Détail  de l\'examen clinique',
                pageDescription: 'Détail de l\'examen clinique', 
          analyseDiff: null,
          has_error: false,
          error_msg: null
        }
    },
    mounted(){
        this.setReview(this.resume)
    },
    watch: {
        examenCliniques: {
            handler(){
                this.setReview(this.resume)
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            setReview: 'sanitaire/SET_REVEIW_EXAMEN',
            done: 'DONE'
        }),
        addAnalyseDiff(){
          let data = {
            animal: this.selectedExamen.animal,
            detail: this.selectedExamen.detail,
            analyseDif: this.analyseDiff
          }
          this.$apollo.mutate({
                mutation: UPDATE_EXAMEN_CLINIQUE,
                variables: {
                    "examen": {
                        ...data
                    },
                    "uid": this.selectedExamen.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Examen clinique ${this.selectedExamen.uid} updated successfully`)
                }
              }).then(() => {
                this.analyseDiff = null
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
          console.log(data)
        }
    },
    computed: {
        ...mapGetters({
            examenCliniques: 'sanitaire/examenCliniques',
            users: 'auth/users',
            animaux: 'identification/animaux',
            is_veterinaire: 'auth/is_veterinaire',
            is_super_admin: 'auth/is_super_admin',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        }),
        selectedExamen(){
            let e = this.examenCliniques.find(item => item.uid === this.$route.params.uid)
            if(e) return e 
            return null
        },
        examExist(){
            return this.selectedExamen !== null && this.selectedExamen !== undefined
        },
        resume(){
            if(this.examExist) return JSON.parse(this.selectedExamen.detail)
            return []
        },
        animal(){
          return (this.examExist) ? this.selectedExamen.animal : null
        },
        createBy(){
          return (this.examExist) ? this.selectedExamen.createBy : null
        },
        createAt(){
          return (this.examExist) ? this.selectedExamen.createdAt : null
        },
        hasAnalyseDiff(){
          if(this.examExist) return this.selectedExamen.analyseDif !== null
          return false
        },
        canAddAnalyseDifferencielle(){
          return this.is_super_admin || this.is_veterinaire || this.is_assistant_veterinaire || this.is_assistant_veto_major
        }
    }

}
</script>

<style lang="scss" scoped>
.informations{
    padding: 20px;
    background-color: #B50404;
    color:  white;
    label{
        display: block;
        padding: 0px 10px;
    }
}

.analyse-diff{
  padding: 10px;
  border-radius: 5px;
  a{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  h6 {
    padding-top: 20px;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase !important;
    color: #1C6100;
  }
}

</style>